@import 'all';

.SiteContent {
  .scrim {
    width: 568px;
    background-color: #ffffff;
    margin-left: 4px;
    padding-bottom: 20px;
  }

  .body-text {
    color: $body-text-color;
    font-family: $font-body;
    padding: 21.3px 47.8px 0;
    margin-bottom: 0;
    width: 100%;
  }

  .body-text p {
    letter-spacing: -0.6px;
    font-size: 16pt;
    text-align: left;
    line-height: 24.7px;
    padding-bottom: 0px;
    margin-bottom: 25px;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .body-text a,
  .body-text a:hover {
    color: $base-color;
    text-decoration: underline;
  }

  .body-text-copyright {
    color: $copyright-color;
    font-size: 13pt;
    line-height: 12.1px;
    padding: 0px 34px 10px;
    letter-spacing: -0.5px;
  }

  .body-legal-notice {
    font-size: 13pt;
    line-height: 12.1px;
    padding: 0px 34px 26.4px;
    letter-spacing: -0.5px;
  }

  @include media-breakpoint-down(sm) {
    .scrim {
      width: 100%;
      margin-left: 0;
      padding-bottom: 0;
      margin-bottom: 0 !important;
    }

    .body-text {
      width: 100% !important;
      padding-left: 35px;
      padding-right: 0px;
      padding-top: 17.1px;
    }

    .body-text p {
      letter-spacing: 0.4px;
      font-size: 15.1px !important;
      text-align: left;
      line-height: 18.9px;
      padding-bottom: 0px;
      margin-bottom: 18.6px;
      -webkit-font-smoothing: subpixel-antialiased;
    }

    .body-text-copyright {
      font-size: 10.8px;
      padding: 0 20px 5px;
      letter-spacing: -0.5px;
    }

    .body-legal-notice {
      margin-bottom: 10px !important;
      font-size: 10.8px;
      line-height: 9.8px;
      padding: 0 20px 20px;
      letter-spacing: -0.5px;
    }
  }
}
