@import "all";

.SiteTitle {
  h1 {
    font-family: "indira_kregular";
    font-size: 33.3px !important;
    color: #fff;
    letter-spacing: 0.4px;
    line-height: 25.6px !important;
    padding-top: 3px;
    padding-bottom: 0;
    text-align: center;
  }

  .logo-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    background: $base-color;
    text-align: left;
    width: 413.3px;
    padding-left: 0;
    height: 61px;
    z-index: 1;
  }

  h1 img {
    max-width: 56%;
  }

  @include media-breakpoint-down(sm) {
    .sitetitle {
      padding: 0 !important;
      float: right;
      display: block;
      top: 0;
      position: absolute !important;
    }
    .logo-container {
      width: 160px;
      padding-left: 0;
      height: 39px;
    }
    .logo-container h1 {
      font-size: 18.1px !important;
      letter-spacing: 2.9px;
      margin-bottom: 1px !important;
      padding: 0.5px 0px;
      line-height: 23.8px !important;
    }
    .logo-container img {
      padding-top: 4px;
      max-width: 84%;
    }
  }
  @media (max-width: 320px) {
    .logo-container {
      min-height: 34px;
    }
  }
}
