@import "all";

.SiteHeading {
  .helvar-merca-profi {
    color: #ffffff;
    font-family: "indira_kregular";
    font-size: 33px;
    line-height: 36px;
    padding: 32px 36px;
    z-index: 0;
  }
  .rectangle-copy {
    max-width: 408px;
    background-color: $base-color;
    margin-left: 5px;
  }

  .helvar-merca-profi p {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-down(sm) {
    background: none !important;

    .heading-container{
      width: 207px;
    }

    .rectangle-copy {
      height: auto;
      margin-left: 0 !important;
      word-break: break-word;

    }

    .helvar-merca-profi {
      padding: 10.9px 34.8px;
    }
    .helvar-merca-profi p {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.5px;
    }

    .heading-container,
    .mobile-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

}
